export default {
  cake: {
    56: '0xb96Bc98eA6A98dB32fa9Af72cD96058318e02E60',
    97: '0x1bc3f9465C0d8cb95258D79E5aAF85c679C76322',
  },
  masterChef: {
    56: '0x625849E705c50788cBAD78e3c81C2258a537B2a2',
    97: '0xB60BcE5fB719b28d4b988a1145929863101af67d',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
  },
  gld: {
    56: '0x1a1c6f9F6B4E535130E9b1de46bBc76130b61Feb',
    97: '',
  },
  usdc: {
    56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  },
}
