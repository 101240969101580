import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [



  {
    pid: 0,
    risk: 5,
    lpSymbol: 'NEGG-BNB LP',
    decimals: 18,
    lpAddresses: {
      97: '0xE729c210A3D3C089De98a4bd351697903580FdDE',
      56: '0xd44F8b91bF365A17f0F0FE0a2Ae0bF5532a2cf0B',
    },
    tokenSymbol: 'NEGG',
    tokenAddresses: {
      97: '0x5A670Cb55B4A1E706470E16104D46F9645c81260',
      56: '0xb96Bc98eA6A98dB32fa9Af72cD96058318e02E60',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'BNB-BUSD LP',
    decimals: 18,
    lpAddresses: {
      97: '0xE729c210A3D3C089De98a4bd351697903580FdDE',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0x5A670Cb55B4A1E706470E16104D46F9645c81260',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 2,
    risk: 5,
    lpSymbol: 'GOLDUCK-BNB LP',
    decimals: 18,
    lpAddresses: {
      97: '0xE729c210A3D3C089De98a4bd351697903580FdDE',
      56: '0x0541Ad728B01A4174A1e901Bc2f80E1386AD46C2',
    },
    tokenSymbol: 'GOLDUCK',
    tokenAddresses: {
      97: '0x5A670Cb55B4A1E706470E16104D46F9645c81260',
      56: '0x1a1c6f9F6B4E535130E9b1de46bBc76130b61Feb',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 3,
    risk: 5,
    lpSymbol: 'GOLDUCK',
    isTokenOnly: true,
    decimals: 9,
    lpAddresses: {
      97: '0xE729c210A3D3C089De98a4bd351697903580FdDE',
      56: '0x0541Ad728B01A4174A1e901Bc2f80E1386AD46C2',
    },
    tokenSymbol: 'GOLDUCK',
    tokenAddresses: {
      97: '0x5A670Cb55B4A1E706470E16104D46F9645c81260',
      56: '0x1a1c6f9F6B4E535130E9b1de46bBc76130b61Feb',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 4,
    risk: 5,
    lpSymbol: 'ADA',
    isTokenOnly: true,
    decimals: 18,
    lpAddresses: {
      97: '0xE729c210A3D3C089De98a4bd351697903580FdDE',
      56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '0x5A670Cb55B4A1E706470E16104D46F9645c81260',
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },




]

export default farms
